import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LandingFondazioneVeronesi from "./landing/fondazioneVeronesi/FondazioneVeronesi";
import "./index.css";


function App() {

  return (
    <div className="App">
        <Router>
            <Routes>
              <Route path="/*" element={<Navigate to="/fondazione-veronesi" replace />} />
              <Route exact path="/fondazione-veronesi" element={<LandingFondazioneVeronesi />} />
            </Routes>   
        </Router>
    </div>
  );
}

export default App;
